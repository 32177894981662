<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="lat">Latitud</label>
          <input
            type="number"
            step="any"
            v-model.number="reportedCenter.lat"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="lng">Longitud</label>
          <input
            type="number"
            step="any"
            v-model.number="reportedCenter.lng"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="radius">Radio</label>
          <input type="number" v-model="radius" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <gmap-map
          :center="center"
          :zoom="15"
          style="width: 100%; height: 400px"
          ref="map"
          @center_changed="update('reportedCenter', $event)"
          @bounds_changed="update('bounds', $event)"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="m.icon"
          ></gmap-marker>
          <gmap-circle
            v-if="displayCircle"
            :center="reportedCenter"
            :bounds="circleBounds"
            :radius="parseFloat(radius)"
            :options="{ editable: true }"
            @radius_changed="editarCirculo('radius', $event)"
            @bounds_changed="editarCirculo('bounds', $event)"
          ></gmap-circle>
        </gmap-map>
      </div>
    </div>
    <div class="row">
      <div class="col">
        Lat: {{ reportedCenter.lat }}, Lng: {{ reportedCenter.lng }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import $ from "jquery";

export default {
  name: "SitioMapCir",

  data() {
    return {
      latitud: null,
      longitud: null,
      markers: [],
      center: {
        lat: 4.651371,
        lng: -74.071509,
      },
      reportedCenter: {
        lat: 4.651371,
        lng: -74.071509,
      },
      places: [],
      edited: null,
      displayCircle: true,
      circleBounds: {},
      mapBounds: {},
      radius: 1000,
    };
  },

  methods: {
    markerSitio() {
      this.latitud = this.$parent.$parent.form.latitud;
      this.longitud = this.$parent.$parent.form.longitud;
      const marker = {
        lat: parseFloat(this.latitud),
        lng: parseFloat(this.longitud),
      };
      this.center = marker;
    },

    update(field, event) {
      if (field === "reportedCenter") {
        this.reportedCenter = {
          lat: event.lat(),
          lng: event.lng(),
        };
      } else if (field === "bounds") {
        this.mapBounds = event;
      } else {
        this.$set(this, field, event);
      }
    },

    editarCirculo(prop, value) {
      if (prop === "radius") {
        this.radius = value;
      } else if (prop === "bounds") {
        this.radius = value;
      }
    },

    initCir() {
      if (
        this.$parent.formGeocerca.latitud !== undefined &&
        this.$parent.formGeocerca.longitud !== undefined
      ) {
        this.reportedCenter = {
          lat: parseFloat(this.$parent.formGeocerca.latitud),
          lng: parseFloat(this.$parent.formGeocerca.longitud),
        };
        this.center = {
          lat: parseFloat(this.$parent.formGeocerca.latitud),
          lng: parseFloat(this.$parent.formGeocerca.longitud),
        };
        this.radius = parseFloat(this.$parent.formGeocerca.radio);
      }
    },
  },

  mounted() {
    //this.markerSitio();
    this.initCir();
  },
};
</script>
